import '../../styles/pages/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2023-stories/more-stories"
import Vimeo from '@u-wave/react-vimeo';


import socialBanner from '../../images/fall-winter-2023/social-banners/sports-broadcast-students-prepare-for-prime-time.jpg';

import gayleJansenBrisbaneAndRobbieLoya from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/Gayle Jansen-Brisbane-and-Robbie-Loya.jpg'
import robbieLoya from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/Robbie-Loya.jpg'
import timothyJacksonAndMayPham from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/Timothy-Jackson-and-May-Pham.jpg'

import grid1 from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/tsn-grid-1.jpg'
import grid2 from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/tsn-grid-2.jpg'
import grid3 from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/tsn-grid-3.jpg'

import ctaBanner from '../../images/fall-winter-2023/sports-broadcast-students-prepare-for-prime-time/cta-banner.jpg'

import heroVideoMP4 from '../../video/fall-winter-2023/tsn/tsn-hero.mp4';
import heroVideoWEBM from '../../video/fall-winter-2023/tsn/tsn-hero.webm';
import heroVideoVTT from 'file-loader!../../video/fall-winter-2023/tsn/tsn-hero.vtt';



export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Sports Broadcast Students Prepare for Prime Time';
    var pageDesc = 'Titans Sports Network gives communications students hands-on experience in producing more than 120 live events for ESPN+ each year. ';
    var slug = 'sports-broadcast-students-prepare-for-prime-time';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn">
          <div className="text">
            <div className='wrap small'>
              <h1 className="animated fadeIn delay-1s slower">Sports Broadcast Students<br />Prepare for <span className='highlight'>Prime Time</span></h1>
              <p className="sub-heading animated fadeInDown delay-1s">{pageDesc}</p>
              <span className="animated fadeInDown delay-1s byline">By Taylor Arrey, Photos by Matt Gush and Robert Huskey</span>
            </div>
          </div>
          <div className="tint"></div>
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" playsInline >
            <source src={heroVideoWEBM} type="video/webm; codecs=vp9,vorbis" />
            <source src={heroVideoMP4} />
            <track kind="captions" srcLang="en" label="English" src={heroVideoVTT} />
          </video>
        </div>
        <article>

          <div className='wrap small'>

            <p><span className='intro-text'>While Titan sports fans file into their seats</span> at Goodwin and Anderson Family fields, broadcast journalism students are on the move behind the scenes. They set up cameras, queue up commercials and graphics for the Titan Gym jumbotron, and prepare the livestream for a national audience.</p>

            <blockquote className='align-right align-outside'>
              <p>To be able to say that I'm 23 years old and I’ve already broadcast more than 100 sports games for a Division I school is incredible.”</p>
              <cite>Robbie Loya</cite>
            </blockquote>

            <p>These students are the driving force behind Cal State Fullerton’s Titans Sports Network, founded in 2021 to give students hands-on experience in sports broadcast media as they livestream events and produce original content that is aired on ESPN’s streaming service, ESPN+. </p>

            <p>One of these students, Robbie Loya, dreams of becoming a play-by-play broadcaster for a professional sports team. A sports fan to his core, Loya grew up with Titan Athletics. He remembers watching baseball games at Goodwin Field and cheering on the women’s volleyball team in Titan Gym. </p>

            <p>Now, he’s calling those games. </p>

            <figure className='align-wide'>
              <Vimeo
                video={857940010}
                responsive
                showTitle={false}
                showByline={false}
                showPortrait={false}
                autoplay={false}
                onEnd={this.closeModal}
                style={{backgroundColor: 'black'}}
                className="video"
              />
            </figure>

            <p>“To be able to say that I'm 23 years old and I’ve already broadcast more than 100 sports games for a Division I school is incredible,” says Loya, a fourth-year communications student. “This network gives students the opportunity to be creative and bolster their resume before they enter a competitive sports industry.”</p>

            <h2>A Unique Partnership With ESPN</h2>
            <p>Timothy Jackson, the facilitator behind the Titans Sports Network, previously served as director of digital and video distribution for the Big West Conference. He was at the forefront of the Big West ESPN Campus Production Initiative. This effort invited all Big West schools to produce content for ESPN+, including Cal State Fullerton, Cal Poly San Luis Obispo, Cal State Bakersfield, Cal State Northridge and Long Beach State. </p>

            <blockquote className='align-left align-outside'>
              <p>We have the resources to help students start their broadcasting careers at Cal State Fullerton.”</p>
              <cite>Timothy Jackson</cite>
            </blockquote>

            <p>“The first school I visited when we started this initiative was Cal State Fullerton and I’ll never forget it. I remember telling the Big West deputy commissioner, ‘I want to work here,’” says Jackson, assistant athletic director of broadcasting, facility and events. “The students are motivated and they want to get better. There’s so much potential.”</p>

            <p>He immediately got to work building the network from the ground up as a collaboration between the College of Communications and Titan Athletics. Tucked in a second floor room in Titan Gym, Jackson established a professional production studio, equipped with television monitors, computers, sound boards, video production systems and an underground cable that runs to each of the athletic venues on campus. </p>

          </div>
          <div className='gallery-grid'>
            <img src={grid1} alt="" />
            <img src={grid2} alt="" />
            <img src={grid3} alt="" />
          </div>
          <div className='wrap small'>

            <p>When he started, the network planned to produce about 60 games every year. However, with state-of-the-art equipment, mentorship from College of Communications faculty and a staff of 20 to 30 students, the network surpassed that goal to produce more than 120 live events. </p>

            <p>Cal State Fullerton also had the largest audience among all schools in the Big West Conference that broadcast to ESPN+, garnering around 900,000 views and reaching a national audience this past year.</p>

            <p>The network broadcasts games for baseball, men and women’s basketball, men and women’s soccer, softball, women’s volleyball, and men and women’s water polo. </p>

            <p>“We have the resources to help students start their broadcasting careers at Cal State Fullerton,” says Jackson. “If students want to be camera operators for ESPN or get into production with NFL Network, they can begin to build the skill sets for those jobs here.”</p>

            <figure className='align-wide'>
              <img src={timothyJacksonAndMayPham} alt="" />
              <figcaption>Timothy Jackson, assistant athletic director of broadcasting, facility and events; and May Pham, communications student</figcaption>
            </figure>

            <p>When third-year communications major May Pham joined the team as an ESPN+ broadcasting student assistant, she didn’t know a lot about sports. With Jackson as a mentor, she learned quickly and after a couple months at the network, she was accepted as a game day operations intern for the Angel City Football Club. </p>

            <p>“Tim is a great mentor. From giving professional advice to networking advice, he looks for opportunities for students and wants us to succeed in whichever roles we take on,” says Pham. “I've seen myself grow tremendously as a communicator and leader.”</p>

            <h2>Getting a Headstart in Their Broadcast Careers</h2>

            <figure className='align-wide'>
              <img src={robbieLoya} alt="" />
              <figcaption>Robbie Loya, communications student</figcaption>
            </figure>

            <p>In addition to livestreaming Titan sporting events, Jackson wanted to give students the opportunity to create original content for ESPN+ that would showcase their on-camera skills and sports reporting abilities. </p>

            <p>Working alongside Gayle Jansen Brisbane, assistant professor of communications, the network team created the Preseason Show, a 30-minute news broadcast produced three times a year to highlight updates for fall, winter and spring sports. Among Big West schools, Cal State Fullerton was the first university to establish an original show for ESPN+. </p>

            <p>“The preseason broadcast shows how Tim and Gayle go above and beyond to create opportunities to help us succeed,” says Loya. “They’re doing their best to make our dreams become reality.”</p>

            <p>Students step in as anchors, reporters, camera operators and graphics coordinators to produce the show alongside on-air guests including coaches and student athletes. </p>

            <p>“Other universities contract their production to outside companies,” says Jansen Brisbane, who has over 20 years of experience in the sports broadcast industry. “It's hard to put into words how much of a head start students get and how this experience puts them above other people in the job market.” </p>

            <p>For students like Loya, who has primarily focused on play-by-play announcing, the network allows them to expand their skills in areas like on-camera hosting and directing.</p>

            <figure className='align-wide'>
              <img src={gayleJansenBrisbaneAndRobbieLoya} alt="" />
              <figcaption>Gayle Jansen Brisbane, assistant professor of communications, and Robbie Loya, communications student</figcaption>
            </figure>

            <p>“Play-by-play broadcasting is still the goal, but learning behind the scenes gave me an open mind. At the end of the day, if I am confident in the product that I put out on ESPN+, it’s a rewarding feeling,” says Loya. </p>

            <p>Loya has received inquiries from such organizations as UC Irvine, California Interscholastic Federation and Stanford University — all interested in hiring him for a multitude of sports broadcasting opportunities.</p>

            <p>“I still have so much to learn and so many ways to get better, but it’s incredible to know that I’m getting offers to work as a broadcaster,” says Loya. <span className='end-mark'/></p>
            
          </div>

        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the Titans Sports Network</span><br />
              <a href="https://give.fullerton.edu/titansportsnetwork">give.fullerton.edu/titansportsnetwork</a>
            </p>
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 